$primaryColor: #fcce5b !default;
$link-color: #206bc4;
$link-hover-color: #1a569d;
/*#eff1fb*/
.showAsLink {
  cursor: pointer;
  color: $link-color;

  &:hover {
    text-decoration: underline;
    color: $link-hover-color;
  }
}

.UserAvatar--inner{
  margin: 0 auto;
}

/* Bootstrap table fill row on cursor move */
.table-hover > tbody > tr:hover:not(.expanding-row) {
  background-color: #dae5f5 !important;
  cursor: pointer;
}

div.react-bootstrap-table table {
  table-layout: auto;
  width: 100%;
}


table.digiTable {
  th {
    cursor: default;
    vertical-align: top !important;
    font-weight: bold;
    text-transform: none;
    font-size: 1em;
    background-color: $primaryColor;
    color: #071258 !important;

    .react-datepicker__month {
      font-weight: normal !important;
    }
  }

  .expand-cell-header,
  .selection-cell-header {
    cursor: pointer;
  }

  table th {
    background-color: #3498db;
    font-size: 0.9em;
    font-weight: 300;
  }

  table {
    font-size: 0.9em;
  }

  td {
    vertical-align: middle !important;
    text-align: center;
  }

  .disabledRow {
    color: lightgrey;
  }

  .row-expansion-style {
    text-align: left !important;
  }

  .greenBackground {
    background-color: #d1e8c4;
  }

  .redBackground {
    background-color: #ffe5d7;
  }

  .yellowBackground {
    background-color: #fffdc2 !important;
  }

  .selectedRow {
    background-color: rgb(219, 228, 245);
  }

  .filter-label {
    display: block;
  }

  .customSelectFilter {
    color: black !important;
    z-index: 10;
  }

  .customSelectFilter.isSelected {
    .reactSelect__control {
      justify-content: flex-end;
    }

    .reactSelect__value-container {
      flex-basis: 100%;
    }
  }

  .header-button {
    padding: 7px 0 7px 10px;
    width: 25px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.transparentButton {
  background-color: transparent;
  border: 0;
  text-align: left;
  padding: 0;
}

/*Button as link setup - mainly for IE11*/
button {
  overflow: visible;
  width: auto;
}

@mixin fs($value) {
  $remValue: calc($value / 16);

  font-size: $value + px;
  font-size: $remValue + rem;
}

.header__logo {
  text-decoration: none;
  position: relative;
  display: inline-block;
  z-index: 5;

  @media (min-width: 580px) and (max-width: 767px) {
    order: -1;
  }

  img {
    position: absolute;
    margin-top: 5px;
    left: 15px;
    @media (max-width: 991px) {
      top: 3px;
      width: 30px;
      height: 30px;
    }

    @media (min-width: 992px) {
      top: -6px;
    }
  }

  @media (max-width: 991px) {
    padding-left: 2.4rem;
  }

  @media (min-width: 992px) {
    padding-left: 4rem;
  }

  span {
    font-weight: 700;
    color: #000000;
    line-height: 40px;
    vertical-align: middle;
    @media (max-width: 991px) {
      @include fs(24);
    }
    @media (min-width: 992px) {
      @include fs(40);
    }
  }
}

button.link {
  border: none;
  background: none;
  text-align: left;
  color: $link-color;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: inline;

  &:hover {
    color: $link-hover-color;
  }
}

.header-search {
  padding-right: 0px !important;
}

.form-input__cancel-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.form-input__cancel-icon:after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '\00d7';
}

td a i.fe,
td button i.fe {
  font-size: larger;
}

.card-alert {
  position: fixed;
  left: 0;
  z-index: 999;
  width: 100%;
  height: auto;
  top: 0;
  font-size: larger;
}

.react-bootstrap-table {
/*  max-height: 800px;
  overflow-y: auto;*/
  thead th {
    position: sticky;
    top: 1px;
    z-index: 200;
  }

  .react-datepicker-popper {
    z-index: 600 !important;
    top: 24px !important;
  }
}

.react-bootstrap-table-sticky-page {
  thead th {
    top: 58px !important;
  }
}


.modal-content {
  .react-bootstrap-table {
    th {
      z-index: 0;
    }
  }
}

.max-height500 {
  .react-bootstrap-table {
    max-height: 500px;
    overflow-y: auto;
    position: relative;
    th {
      position: sticky;
      top: -2px;
    }
  }
}

.reactSelect__menu-portal {
  z-index: 1000 !important;
  min-width: 300px;
}

.badge-wrap {
  white-space: break-spaces;
  height: auto;
  min-height: 1.25rem;
}

.place-center {
  display: grid;
  place-items: center;
}

.text-red {
  color: red;
}

.modal-content {
  max-height: 90vh;
}

.modal-body {
  overflow: auto;
}

.bg-green-leaves {
  color: white;
  background: #3A5F0B; }

.bg-dark-beige {
  color: white;
  background: #9F8C76; }

.bg-golden-yellow {
  color: black;
  background: #FFDF00; }

.bg-chestnut {
  color: white;
  background: #954535; }

.react-datepicker-wrapper {
  display: inline !important; }

.react-datepicker-popper {
  z-index: 1200 !important; }

.gwt-SuggestBoxPopup {
  z-index: 10000 !important; }

.stronger {
  font-weight: 800; }

.bg-overlay-80
{
  background-color: rgba(255, 255, 255, 0.8);
}

.sticky-container {
  position: sticky;
  top: 20px; /* Adjust top distance to control where it starts sticking */
  z-index: 10; /* Adjust if necessary for layering */
}


.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* Match the card background color */
  z-index: 10; /* Ensure it is above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for better visibility */
  padding: 10px 20px; /* Maintain consistent padding */
}