
.dropZoneBase {
  height: 250px;
  border-width: 2px;
  border-color: #666;
  border-style: dashed;
  border-radius: 5px;
  padding: 50px;
}

.dropZoneActive {
  border-style: solid;
  border-color: #1bb269;
  background-color: lighten(#1bb269 , 50%);
}

.dropZoneReject {
  border-style: solid;
  border-color: #c66;
  background-color: #eee;
}

.dropZoneOverlay {
  position: relative;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: not-allowed;
}
