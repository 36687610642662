
.smallerHeadline {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 700;
}
.headline {
    font-size: 3.5rem;
    line-height: 1.2;
    font-weight: 700;
}